import BasicStructure from '../components/basic-structure/basic-structure';
import React from 'react';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';
import ContentContainer from '../components/content-container/content-container';
import Footer from '../components/footer/footer';
import { getWpBlock } from '../components/wp-blocks';
import { graphql } from 'gatsby';
import Header from '../components/header/header';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';
import Sidebar from '../components/sidebar/sidebar';
import ScrollUp from '../components/scroll-up/scroll-up';
import './fischers-corner.css';
import { WpPost, WpPostConnection } from '../../generated/graphql';

interface Props {
  data: {
    allWpPost: WpPostConnection;
  };
}
export default function FischersCorner(props: Props): JSX.Element {
  const posts = props.data?.allWpPost?.edges.map((edge) => edge.node);
  return (
    <BasicStructure>
      <TitleMetaTags title="Fischer's corner" />
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu />
      </Header>
      <Sidebar>
        <ScrollUp />
      </Sidebar>
      <ContentContainer>
        <div className="fischers-corner__first-article">
          Die Antwort auf die Frage nach Sinn, Zweck und Ursache
          <br />
          dieser Veröffentlichungen ergeben sich aus der Präambel der
          <br /> Stiftungssatzung.
        </div>
        <section className="fischers-corner-page">
          {posts &&
            posts.map((post) => {
              return (
                <article key={post.id} className="layout--margin-60">
                  {post.blocks.length > 0 &&
                    post.blocks.map((block) => {
                      const WpBlock = getWpBlock(block.__typename);
                      if (!WpBlock) return null;
                      return (
                        <div className="content-block" key={block.id}>
                          <WpBlock block={block} />
                        </div>
                      );
                    })}
                </article>
              );
            })}
        </section>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export const pageQuery = graphql`
  query fischersCornerPosts {
    allWpPost(
      limit: 1000
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { eq: "fischers-corner" } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          blocks {
            __typename
            dynamicContent
            isDynamic
            originalContent
            saveContent
            ...WPColumns
            ...WpCoreImageBlockFragment
            ...WpCoreGalleryBlockFragment
            innerBlocks {
              __typename
              saveContent
              isDynamic
              dynamicContent
              ...WpCoreImageBlockForGalleryFragment
              ...WpCoreGalleryBlockFragment
            }
          }
        }
      }
    }
  }
`;
